// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import feather from 'feather-icons'
import Action from './components/action'
import Setter from './components/setter'
document.addEventListener("DOMContentLoaded", () => {
    const actions = document.querySelectorAll(".js-action")
    actions.forEach((el) => {
        const act = new Action(el)
        
    })

    const setters = document.querySelectorAll('.js-set')
    setters.forEach((el) => {
        const set = new Setter(el)
    })
})
feather.replace()