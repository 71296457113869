export default class Action {
    constructor(el) {
        this.el = el 
        this.selected = el.classList.contains("active")
        this.day = el.dataset.day
        this.slot = el.dataset.slot 
        this.av_type = el.dataset.type
        this.user = el.dataset.user
        this.init()
    }
    init() {
        //alert("ciao")
        this.el.addEventListener("click", () => {
            this.handleClick()
        })
        
    }

    handleClick() {
        this.el.classList.toggle("selected")
        this.postData('/booking', {
            day: this.day,
            slot: this.slot,
            av_type: this.av_type,
            user: this.user
        })
        .then((data) => {
          console.log(data); // JSON data parsed by `data.json()` call
          window.location.reload()
          
        });
    }

    async postData(url = '', data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        return response.json(); // parses JSON response into native JavaScript objects
    }
}